import { VApp } from 'vuetify/lib/components/VApp';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,{class:{
    'sidebar-close': !_vm.getThemeMode.verticalSidebarDrawer
  },style:({
    background: _vm.$vuetify.theme.themes[_vm.theme].background,
    color: '#304156'
  })},[_c('side-bar',{attrs:{"app":""}}),_c('app-bar',{attrs:{"app":""}}),_c('base-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }